import React, { memo } from "react";
import "Assets/Styles/spinner.scss";
import Navigator from "./Navigator";

const Spinner = () => {
  return (
    <>
      <Navigator />
      <div className={"spinner"}>
        <img
          className="spinner-img"
          src={require("Assets/Images/layout/spinner.gif")}
          alt="spinner"
        ></img>
      </div>
    </>
  );
};

export default memo(Spinner);
