import * as types from "./constants.js";
import { actions } from "..";
import { Update } from "../Error/actions";
import postRequest from "Api/PostRequests";
import getRequest from "Api/GetRequests";

export const Login = (form) => {
  // async call
  return (dispatch) => {
    dispatch(actions.app.loading(true));
    dispatch(Loading(true));

    const result = postRequest(form, "login");

    result
      .then(({ error, data }) => {
        if (error) {
          dispatch(Update(error));
        } else {
          dispatch({
            type: types.LOGIN,
            payload: {
              ...data,
            },
          });
          localStorage.setItem("afrika-token", data.token);
        }

        dispatch(actions.app.loading(false));
        dispatch(Loading(false));
      })
      .catch((error) => {
        dispatch(actions.app.loading(false));
        dispatch(Loading(false));
      });
  };
};

export const Register = (form) => {
  // async call
  return (dispatch) => {
    dispatch(actions.app.loading(true));
    dispatch(Loading(true));

    const result = postRequest(form, "register");

    result
      .then(({ error, data }) => {
        if (error) {
          dispatch(Update(error));
        } else {
          dispatch({
            type: types.REGISTER,
            payload: {
              ...data,
            },
          });
          localStorage.setItem("afrika-token", data.token);
        }

        dispatch(actions.app.loading(false));
        dispatch(Loading(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(actions.app.loading(false));
        dispatch(Loading(false));
      });
  };
};

export const GetUser = (token) => {
  // async call
  return (dispatch) => {
    dispatch(actions.app.loading(true));
    dispatch(Loading(true));

    const result = getRequest({}, token, "user");

    result
      .then(({ data }) => {
        if (data.hasOwnProperty("error")) {
          dispatch(Update(data));
        } else {
          dispatch({
            type: types.LOAD_USER,
            payload: data,
          });
        }
        dispatch(actions.app.loading(false));
        dispatch(Loading(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(actions.app.loading(false));
        dispatch(Loading(false));
      });
  };
};

export const UpdateUser = (form, token) => {
  // async call
  return (dispatch) => {
    dispatch(actions.app.loading(true));
    dispatch(Loading(true));

    const result = postRequest(form, "user/update", token);

    result
      .then(({ data }) => {
        if (data.hasOwnProperty("error")) {
          dispatch(Update(data));
        } else {
          dispatch({
            type: types.UPDATE,
            payload: data,
          });
        }
        dispatch(actions.app.loading(false));
        dispatch(Loading(false));
        dispatch(actions.notification.SetMessage("Profile updated!"));
      })
      .catch((error) => {
        console.log(error);
        dispatch(actions.app.loading(false));
        dispatch(Loading(false));
      });
  };
};

export const Logout = (token) => {
  // async call
  return (dispatch) => {
    dispatch(actions.app.loading(true));
    dispatch(Loading(true));

    const result = postRequest({}, "logout", token);

    result
      .then(({ data }) => {
        dispatch({
          type: types.LOGOUT,
          payload: {
            ...data,
          },
        });
        dispatch(actions.app.loading(false));
        localStorage.removeItem("afrika-token");
        dispatch(Loading(false));
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        dispatch(actions.app.loading(false));
        dispatch(Loading(false));
      });
  };
};

export const Loading = (isLoading) => {
  return (dispatch) => {
    dispatch({
      type: types.LOADING_STATUS,
      payload: isLoading,
    });
  };
};
