import {api as url} from './config';
import serialize from './Serialize';

//Handles all post requests including file uploads, Bearer token is compulsory, 
//only authenticated users can post data to api
const postRequest = async (form, uri, token = '', processed = false) => {
    let formData = (processed) ? form : serialize(form);

    const response = await fetch(`${url}${uri}`, {
        method: 'POST', 
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + token
        },
        body: formData
      });

      return response.json();
}

export default postRequest;