import { User, UserState } from "./User";
import { Profile, ProfileState } from "./Profile";
import { Error, ErrorState } from "./Error";

export const reducers = {
  user: User.reducers,
  profile: Profile.reducers,
  error: Error.reducers,
};

export const actions = {
  user: User.actions,
  profile: Profile.actions,
  error: Error.actions,
};

export const states = {
  user: UserState,
  profile: ProfileState,
  error: ErrorState,
};
