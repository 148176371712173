import { reducers, actions, states } from "./Modules";
import thunk from "redux-thunk";
import {
  createStore as _createStore,
  combineReducers,
  applyMiddleware,
} from "redux";

const createStore = _createStore(
  combineReducers(reducers),
  {},
  applyMiddleware(thunk)
);

export { createStore, actions, states, reducers };
