import React, { useState, useEffect } from "react";

export function NumberFormatter({ number }) {
  const [value, setValue] = useState(parseInt(number));

  useEffect(() => {
    convert();
  }, []);

  const convert = () => {
    let result =
      Math.abs(value) > 999
        ? Math.sign(value) * (Math.abs(value) / 1000).toFixed(1) + "k"
        : Math.sign(value) * Math.abs(value);

    setValue(result);
  };

  return <>{value}</>;
}
