import React from "react";
import "Assets/Styles/searchform.scss";

export default function SearchFrom({ searchPlaceholder, styleType, isSmallBtn }) {
  const search = (e) => {
    e.preventDefault();
  };

  const onSearchClick = () => {
    window.dataLayer.push({
      event: 'search_action',
      page_link: window.location.pathname
    })
  }

  return (
    <div className={`search-form ${styleType}`}>
      <form onSubmit={(e) => search(e)}>
        <div className="input-box">
          <input placeholder={searchPlaceholder} />
          <button className={`btn ${isSmallBtn ? 'small' : ''}`} onClick={onSearchClick}>search</button>
        </div>
      </form>
    </div>
  );
}
