import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { Spinner, AudioPlayer } from "../Components";
import ScrollToTop from "./ScrollToTop";

const Home = lazy(() => import("../Modules/Home"));
const Event = lazy(() => import("../Modules/Event"));
const EventView = lazy(() => import("../Modules/Event/Components/EventView"));
const VoteCategoryView = lazy(() =>
  import("../Modules/Event/Components/VoteCategoryView")
);
const TicketDetails = lazy(() =>
  import("../Modules/Event/Components/TicketDetails")
);
const PaymentFail = lazy(() =>
  import("../Modules/Event/Components/PaymentFail")
);
const News = lazy(() => import("../Modules/News"));
const NewsPage = lazy(() => import("../Modules/News/Components/NewsPage"));
const About = lazy(() => import("../Modules/Company/Components/About"));
const Contact = lazy(() => import("../Modules/Company/Components/Contact"));
const Music = lazy(() => import("../Modules/Music"));
const Movies = lazy(() => import("../Modules/Movies"));
const Videos = lazy(() => import("../Modules/Videos"));
const AllMusic = lazy(() => import("../Modules/Music/Components/AllMusic"));
const SongView = lazy(() => import("../Modules/Music/Components/SongView"));
const MixView = lazy(() => import("../Modules/Music/Components/MixView"));
const Artist = lazy(() => import("../Modules/Music/Components/Artists"));
const AllArtists = lazy(() => import("../Modules/Music/Components/AllArtists"));
const Playlist = lazy(() => import("../Modules/Music/Components/Playlist"));
const NotFound = lazy(() => import("../Modules/Missing"));
const WatchVideo = lazy(() =>
  import("../Modules/Videos/Components/WatchVideo")
);
const WatchMovie = lazy(() =>
  import("../Modules/Movies/Components/WatchMovie")
);
const MovieCategory = lazy(() =>
  import("../Modules/Movies/Components/MovieCategory")
);
const MusicCategory = lazy(() =>
  import("../Modules/Music/Components/MusicCategory")
);
const VideoCategory = lazy(() =>
  import("../Modules/Videos/Components/VideoCategory")
);
const NomineePage = lazy(() =>
  import("../Modules/Event/Components/NomineePage")
);

export default function Routes() {
  return (
      <AudioPlayer>
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about-us" exact component={About} />
            <Route path="/contact-us" exact component={Contact} />
            <Route path="/events" exact component={Event} />
            <Route path="/event/:id" exact component={EventView} />
            <Route path="/payment-fail" exact component={PaymentFail} />
            <Route path="/nominee/:id" exact component={NomineePage} />
            <Route path="/vote-category/:id" exact component={VoteCategoryView} />
            <Route path="/ticket/:id" exact component={TicketDetails} />
            <Route path="/music" exact component={Music} />
            <Route path="/movies" exact component={Movies} />
            <Route path="/video" exact component={Videos} />
            <Route path="/artists" exact component={AllArtists} />
            <Route path="/artist/:id" exact component={Artist} />
            <Route path="/video/:id" exact component={WatchVideo} />
            <Route path="/movie/:id" exact component={WatchMovie} />
            <Route path="/movie-category/:id" exact component={MovieCategory} />
            <Route path="/video-category/:id" exact component={VideoCategory} />
            <Route path="/music-category/:id" exact component={MusicCategory} />
            <Route path="/all-music" exact component={AllMusic} />
            <Route path="/music/:id" exact component={SongView} />
            <Route path="/mix/:id" exact component={MixView} />
            <Route path="/album/:id" exact component={Playlist} />
            <Route path="/inside-africa" exact component={News} />
            <Route path="/inside-africa/:id" exact component={NewsPage} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </AudioPlayer>
  );
}
