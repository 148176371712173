import React, { useRef, useState, useEffect } from "react";
import "Assets/Styles/player.scss";
import { MusicPlayerContext } from "Context/AudioPlayerContext";
import { useLocation } from "react-router-dom";
import GetRequest from "Api/GetRequests";
import postRequest from "Api/PostRequests";
import AppUrl, { adminApi } from "Api/config";

export default function Player(props) {
  // const location = useLocation();

  const audio = useRef("audio_tag");
  const playlistRef = useRef("playlist");
  const playlistCopyRef = useRef("playlistCopy");
  const minimizer = useRef("mini");
  const playerUiRef = useRef("player");
  const volumeRef = useRef("volume-tracker");

  let [defaultSongs, setDefaultSongs] = useState([]);
  let [playing, setPlayState] = useState(0);
  let [bufferedLength, setBufferedLength] = useState(0);
  let [currentTimeLength, setCurrentTimeLength] = useState(0);
  let [playedTime, setPlayedTime] = useState("0:00");
  let [audioLength, setAudioLength] = useState("0:00");
  let [title, setTitle] = useState("Title");
  let [artist, setArtist] = useState("Artist");
  let [cover, setCover] = useState("");
  let [playingAlbum, setPlayingAlbum] = useState(false);

  useEffect(() => {
    if (defaultSongs.length === 0) {
      let defSongs = GetRequest({}, "", `${adminApi}/music-advert`);

      defSongs
        .then(({ data }) => {
          setDefaultSongs([...data]);
        })
        .catch((error) => {
          console.log({ error });
        });
    }

    loadDefaultPlaylist();
  }, [defaultSongs]);

  const addView = (id) => {
    let formData = new FormData();

    formData.append("view_type", "music");
    formData.append("view_id", id);

    let result = postRequest(formData, "admin/view", "", true);

    result
      .then(({ data }) => {
        // console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    audio.current.addEventListener("timeupdate", function () {
      updateBufferedLength();
      updateCurrentTimeLength();
      updateTime();
    });

    minimizer.current.addEventListener("click", function () {
      playerUiRef.current.classList.toggle("minimize-player");
    });

    audio.current.addEventListener("loadedmetadata", function () {
      updateTime();
      updateAudioLength();
    });

    volumeRef.current.addEventListener("click", function (e) {
      volumeRef.current.querySelector(".tracker").style.width =
        (e.layerX / 155) * 100 + "%";
      audio.current.volume = e.layerX / 155;
    });

    audio.current.addEventListener("ended", function () {
      next();
    });
  }, []);

  // do something when route is changed
  // useEffect(() => {
  //   if (
  //     !location.pathname.includes("/all-music") ||
  //     !location.pathname.includes("/album") ||
  //     !location.pathname.includes("/artist")
  //   ) {
  //     setPlayingAlbum(false);
  //   }
  // }, [location]);

  const updateBufferedLength = () => {
    audio.current.onprogress = () => {
      if (audio.current.target === 4) {
        let buffered = audio.current.buffered.end(0) / audio.current.duration;
        setBufferedLength(buffered * 100 + "%");
      }
    };
  };

  const updateCurrentTimeLength = () => {
    let currentTime = audio.current.currentTime / audio.current.duration;
    setCurrentTimeLength(currentTime * 100 + "%");
  };

  const updateTime = () => {
    let duration = (audio.current.currentTime / 100)
      .toFixed(2)
      .replace(".", ":");
    setPlayedTime(duration);
  };

  const updateAudioLength = () => {
    let duration = (audio.current.duration / 100).toFixed(2).replace(".", ":");
    setAudioLength(duration);
  };

  const next = () => {
    let currentMusic = null;

    let virtualCurrent = playlistCopyRef.current.querySelector(".active");

    if (!playingAlbum) {
      currentMusic = playlistCopyRef.current.querySelector(".active");
    } else {
      currentMusic = playlistRef.current.querySelector(".active");
    }

    if (currentMusic.nextSibling) {
      let next = currentMusic.nextSibling;
      let virtualNext = virtualCurrent.nextSibling;
      playSong(next, virtualNext);
    } else {
      // setPlayState(0);
    }
  };

  const prev = () => {
    let currentMusic = null;

    let virtualCurrent = playlistCopyRef.current.querySelector(".active");

    if (!playingAlbum) {
      currentMusic = playlistCopyRef.current.querySelector(".active");
    } else {
      currentMusic = playlistRef.current.querySelector(".active");
    }

    if (currentMusic.previousSibling) {
      let previous = currentMusic.previousSibling;
      let virtualPrevious = virtualCurrent.previousSibling;
      playSong(previous, virtualPrevious);
    }
  };

  const playSong = (song, song2, play = true, album = false) => {
    if (album || playingAlbum) {
      let allSongs = playlistRef.current.querySelectorAll("tr");

      allSongs.forEach((s) => {
        s.classList.remove("active");
      });

      song.classList.add("active");
    }

    let allSongs2 = playlistCopyRef.current.querySelectorAll("tr");

    allSongs2.forEach((s2) => {
      s2.classList.remove("active");
    });

    song2.classList.add("active");

    let src = song2.getAttribute("data-src");
    let cover = song2.getAttribute("data-cover");
    let title = song2.getAttribute("data-title");
    let artist = song2.getAttribute("data-artist");
    let id = song2.getAttribute("data-id");

    setTitle(title);
    setCover(cover);
    setArtist(artist);

    audio.current.src = src;

    if (id) {
      addView(id);
    }

    if (play) {
      playAudio();

      setPlayState(1);
    }
  };

  const loadDefaultPlaylist = () => {
    if (defaultSongs.length > 0) {
      const firstSong = playlistCopyRef.current.querySelector("tr:first-child");

      playSong(firstSong, firstSong, false);
    }
  };

  const playAlbum = (e) => {
    setPlayingAlbum(true);

    playlistCopyRef.current.querySelectorAll("tr").forEach((r) => {
      r.remove();
    });

    const firstSong = playlistRef.current.querySelector("tr:first-child");

    let allSongs = playlistRef.current.querySelectorAll("tr");

    if (allSongs.length === 0) {
      return;
    }

    allSongs.forEach((song) => {
      let cln = song.cloneNode(true);
      playlistCopyRef.current.append(cln);
    });

    const firstSong2 = playlistCopyRef.current.querySelector("tr:first-child");

    playSong(firstSong, firstSong2, true, true);
  };

  const playAudio = () => {
    var playPromise = audio.current.play();

    if (playPromise !== undefined) {
      playPromise.then((_) => {}).catch((error) => {});
    }
  };

  const play = (e) => {
    if (!audio.current.src) return;

    if (audio.current.paused) {
      playAudio();
      setPlayState(1);
    } else {
      audio.current.pause();
      setPlayState(0);
    }
  };

  const repeat = () => {
    audio.current.currentTime = 0;
    playAudio();
    setPlayState(1);
  };

  const handleSongClick = (e) => {
    let element = e.target.parentElement;
    let row = element.tagName === "TD" ? element.parentElement : element;

    setPlayingAlbum(true);

    playlistCopyRef.current.querySelectorAll("tr").forEach((r) => {
      r.remove();
    });

    let allSongs = playlistRef.current.querySelectorAll("tr");

    allSongs.forEach((song) => {
      let cln = song.cloneNode(true);
      playlistCopyRef.current.append(cln);
    });

    let row2 = playlistCopyRef.current.querySelector(
      `tr[data-id='${row.getAttribute("data-id")}']`
    );

    playSong(row, row2, true, true);
  };

  return (
    <MusicPlayerContext.Provider
      value={{
        playlist: {},
        clickSong: handleSongClick,
        playAlbum: playAlbum,
        play: play,
        playListRef: playlistRef,
      }}
    >
      <div className={'main-layout'}>
        {props.children}

        <table>
          {/* Virtual playlist, an invisible playlist that has a
                predefined list of songs by default, and its updated when a user clicks play on an album or songs, this is to keep track of the
                list of songs as we navigate thru the website, bcus this particular component is a parent component of the entire website */}
          <tbody className="virtual-playlist" ref={playlistCopyRef}>
          {defaultSongs.length > 0 ? (
              defaultSongs.map((song, index) => (
                  <tr
                      data-title={song.title}
                      data-artist={song.artist_name}
                      data-src={`${AppUrl}/storage/musicAudio/${song.music_path}`}
                      data-cover={`${AppUrl}/storage/musicCover/${song.image_path}`}
                      key={index}
                      className={"song-row"}
                  ></tr>
              ))
          ) : (
              <></>
          )}
          </tbody>
        </table>

        <div className={'bottom-navigator'}>
          <div className={`music-player fixed-player`} ref={playerUiRef}>
            <audio ref={audio} preload={"auto"} />

            <span className="minimizer" ref={minimizer}>
          O
        </span>

            <div className="audio-details">
              <div className="maximize">
                <div
                    className="audio-cover"
                    style={{
                      backgroundImage: `url(${cover})`,
                    }}
                ></div>
                <div className="audio-info">
                  <h5 className="audio-name">{title}</h5>
                  <p className="audio-artist">{artist}</p>
                </div>
              </div>

              <div className="minimize">
                <div className="controls">
              <span className="repeat" onClick={repeat}>
                <img
                    src={require("Assets/Images/player/repeat.svg").default}
                    alt="repeat icon"
                />
              </span>
                  <span className="previous" onClick={prev}>
                <img
                    src={require("Assets/Images/player/previous.svg").default}
                    alt="repeat icon"
                />
              </span>
                  <span className="play" onClick={(e) => play(e)}>
                {playing ? (
                    <img
                        src={require("Assets/Images/player/pause.svg").default}
                        alt="repeat icon"
                    />
                ) : (
                    <img
                        src={require("Assets/Images/player/play.svg").default}
                        alt="repeat icon"
                    />
                )}
              </span>
                  <span className="next" onClick={next}>
                <img
                    src={require("Assets/Images/player/next.svg").default}
                    alt="repeat icon"
                />
              </span>
                  <span className="shuffle">
                <img
                    src={require("Assets/Images/player/shuffle.svg").default}
                    alt="repeat icon"
                />
              </span>
                </div>
              </div>
            </div>
            <div className="audio-controls">
              {/* <div className="audio-info-mobile">
                        <p><span className='audio-name'>{title}</span> - <span className="audio-artist">{artist}</span></p>
                    </div> */}
              <div className="controls">
            <span className="repeat" onClick={repeat}>
              <img
                  src={require("Assets/Images/player/repeat.svg").default}
                  alt="repeat icon"
              />
            </span>
                <span className="previous" onClick={prev}>
              <img
                  src={require("Assets/Images/player/previous.svg").default}
                  alt="repeat icon"
              />
            </span>
                <span className="play" onClick={(e) => play(e)}>
              {playing ? (
                  <img
                      src={require("Assets/Images/player/pause.svg").default}
                      alt="repeat icon"
                  />
              ) : (
                  <img
                      src={require("Assets/Images/player/play.svg").default}
                      alt="repeat icon"
                  />
              )}
            </span>
                <span className="next" onClick={next}>
              <img
                  src={require("Assets/Images/player/next.svg").default}
                  alt="repeat icon"
              />
            </span>
                <span className="shuffle">
              <img
                  src={require("Assets/Images/player/shuffle.svg").default}
                  alt="repeat icon"
              />
            </span>
              </div>
              <div className="duration-tracker">
                <span className="current-time time">{playedTime}</span>
                <div
                    className="current-tracker"
                    style={{ width: currentTimeLength }}
                ></div>
                <span className="total-time time">{audioLength}</span>
              </div>
            </div>
            <div className="volume-box">
              <img
                  src={require("Assets/Images/player/volume.svg").default}
                  alt="volume"
              />
              <div className="volume-tracker" ref={volumeRef}>
                <div className="tracker"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MusicPlayerContext.Provider>
  );
}
