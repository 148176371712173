import React, { useEffect } from "react";
import "Assets/Styles/slider3d.scss";
import $ from "jquery";

export default function Slider3D(props) {
  let { slides } = props;

  useEffect(() => {
    let Conclave = (function () {
      let buArr = [],
        arlen;
      return {
        init: function () {
          this.addCN();
          this.clickReg();
        },
        addCN: function () {
          let buarr = [
            "holder2_bu_awayL2",
            "holder2_bu_awayL1",
            "holder2_bu_center",
            "holder2_bu_awayR1",
            "holder2_bu_awayR2",
          ];
          for (let i = 1; i <= buarr.length; ++i) {
            $("#bu" + i)
              .removeClass()
              .addClass(buarr[i - 1] + " holder2_bu");
          }
        },
        clickReg: function () {
          $(".holder2_bu").each(function () {
            buArr.push($(this).attr("class"));
          });
          arlen = buArr.length;
          for (let i = 0; i < arlen; ++i) {
            buArr[i] = buArr[i].replace(" holder2_bu", "");
          }
          $(".holder2_bu").click(function (buid) {
            let tomove = 0;

            let me = this,
              id = this.id || buid,
              joId = $("#" + id),
              joCN = joId.attr("class").replace(" holder2_bu", "");
            let cpos = buArr.indexOf(joCN),
              mpos = buArr.indexOf("holder2_bu_center");
            if (cpos != mpos) {
              tomove = cpos > mpos ? arlen - cpos + mpos : mpos - cpos;
              while (tomove) {
                let t = buArr.shift();
                buArr.push(t);
                for (let i = 1; i <= arlen; ++i) {
                  $("#bu" + i)
                    .removeClass()
                    .addClass(buArr[i - 1] + " holder2_bu");
                }
                --tomove;
              }
            }
          });
        },
        auto: function () {
          for (let i = 1; i <= 1; ++i) {
            $(".holder2_bu")
              .delay(4000)
              .trigger("click", "bu" + i)
              .delay(4000);
            console.log("called");
          }
        },
      };
    })();

    $(document).ready(function () {
      window["conclave"] = Conclave;
      Conclave.init();
    });
  }, []);

  return (
    <>
      {slides.map((slide, index) => (
        <div
          key={index}
          id={`bu${index + 1}`}
          style={{
            backgroundImage: `url(${slide.img})`,
          }}
        ></div>
      ))}
    </>
  );
}
