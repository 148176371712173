import { handleActions } from "redux-actions";
import * as types from "./constants";

export let ErrorState = "";

export default handleActions(
  {
    [types.ERROR_UPDATE]: (state = ErrorState, actions) => {
      return actions.payload;
    },
    [types.ERROR_UPDATE]: (state = ErrorState, actions) => {
      return actions.payload;
    },
  },
  ErrorState
);
