import React from "react";
import { Link } from "react-router-dom";
import { Footer, Aside, SearchForm, Navigator } from './index'
import "Assets/Styles/layout.scss";

export default function DefaultLayout(props) {
    let {
        isSubCategoryLayout,
        category,
        subcategories,
        generalHeaderLink,
        search,
        showGeneralHeader,
        generalHeaderCategory,
        searchText,
    } = props;

    return (
        <>
            <Navigator />
            <main id="layout">
                {!isSubCategoryLayout && props.children}
                { isSubCategoryLayout &&
                    <div className="subcat-layout">
                        <div className="left-menu">
                            <Aside category={category} subcategories={subcategories} />
                        </div>
                        <div className="content">
                            <div className="inner-content">
                                {showGeneralHeader && (
                                    <div className="general-header">
                                        <div className="icons-area">
                                            <Link to={generalHeaderLink} className="icon">
                                                <img
                                                    alt="Icon"
                                                    src={require("Assets/Images/layout/back.svg").default}
                                                />
                                            </Link>
                                            {/* <span className='icon'>
                                            <img src={ require('Assets/Images/layout/arrow-back.svg').default } />
                                        </span> */}
                                            <span className="nav-info">
                                        <p>{generalHeaderCategory}</p>
                                      </span>
                                        </div>
                                        {
                                            search ? (
                                                <div className="search-area">
                                                    <SearchForm
                                                        searchPlaceholder={searchText}
                                                        styleType={"full-width"}
                                                    />
                                                </div>
                                            ) : ("")
                                        }
                                    </div>
                                )}
                                {props.children}
                            </div>
                        </div>
                    </div>
                }
                <Footer />
            </main>
        </>

    );
}
