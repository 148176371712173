//Create form data from form elements

const serialize = (form) => {  
    const fData = new FormData();

    for(let el in form){
        if (form[el].name !== 'namedItem' && form[el].name !== 'item' && !form[el].disabled && 
        form[el].type !== 'file' && form[el].type !== 'reset' && typeof form[el].name !== 'undefined'
        && form[el].type !== 'submit' && form[el].type !== 'button'){
          fData.append(form[el].name, form[el].value);  
        }else if(form[el].type === 'file'){
          fData.append(form[el].name, form[el].files[0]);
        }
     
    }
  
    return fData;
}

export default serialize;