import React from "react";
import { Link } from "react-router-dom";

const footerItems = [
  {
    category: {
      name: 'Information',
      items: [
        {
          label: 'About Us',
          anchor: '/about-us',
          isExternal: false
        },
        {
          label: 'Contact Us',
          anchor: '/contact-us',
          isExternal: false
        },
      ]
    }
  },
  {
    category: {
      name: 'Help Link',
      items: [
        {
          label: 'Privacy policy',
          anchor: '#',
          isExternal: false
        },
        {
          label: 'Terms of service',
          anchor: '#',
          isExternal: false
        },
        {
          label: 'FAQ',
          anchor: '#',
          isExternal: false
        },
      ]
    }
  },
  {
    category: {
      name: 'Marketing',
      items: [
        {
          label: 'Advertise with Us',
          anchor: '#',
          isExternal: false
        },
        {
          label: 'Artiste promotion',
          anchor: '#',
          isExternal: false
        },
        {
          label: 'Music promotion',
          anchor: '#',
          isExternal: false
        },
      ]
    }
  },
  {
    category: {
      name: 'Connect with Us',
      items: [
        {
          label: 'Facebook',
          anchor: '#',
          isExternal: true
        },
        {
          label: 'Instagram',
          anchor: '#',
          isExternal: true
        },
        {
          label: 'Twitter',
          anchor: '#',
          isExternal: true
        },
      ]
    }
  }
]

export default function Footer() {
  const onFooterLinkClick = (link) => {
    window.dataLayer.push({
      event: 'footer_link_click',
      link
    })
  }

  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="footer-content">
            <div className="footer-logo">
              <img
                src={require("Assets/Images/layout/logo1.svg").default}
                alt="footer-log"
              />
            </div>
            {
              footerItems.map(footerItem => (
                  <div className="footer-col">
                    <h4>{footerItem.category.name}</h4>
                    <ul>
                      {
                        footerItem.category.items.map(item => (
                            <li onClick={() => onFooterLinkClick(item.label)}>
                              {
                                !item.isExternal ?
                                    <Link to={item.anchor}>{item.label}</Link> :
                                    <a href={item.anchor} target={'_blank'} rel="noreferrer">{item.label}</a>
                              }
                            </li>
                        ))
                      }
                    </ul>
                  </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="company-copy-right">
        <p>Copyright C 2020 Afrika+ Limited. All rights reserved.</p>
      </div>
    </footer>
  );
}
