import * as types from "./constants";

export const Update = (data) => {
  console.log("error updating");

  return (dispatch) => {
    dispatch({
      type: types.ERROR_UPDATE,
      payload: data,
    });
  };
};

export const Clear = () => {
  console.log("error clearing");
  return (dispatch) => {
    dispatch({
      type: types.ERROR_CLEAR,
      payload: {},
    });
  };
};
