import React, { memo } from "react";
import "Assets/Styles/spinner.scss";

const Loader = () => {
  return (
    <>
      <div className={"loader"}>
        <img
          className="loader-img"
          src={require("Assets/Images/layout/spinner.gif")}
          alt="Loader"
        ></img>
      </div>
    </>
  );
};

export default memo(Loader);
