import {api as url} from './config';

const prepareParams = (param) => {
  let query = "";
  for (const [key, value] of Object.entries(param)) {
    query += `${key}= ${value}&`;
  }

  return query;
}

//Handles GET requests, bearer token is optional, depends on the requirement of the api endpoint
const getRequest = async (params = {}, token = "", uri) => {
    // let body = prepareBody(data);
    let query = prepareParams(params);

    const response = await fetch(`${url}${uri}${query}`, {
        method: 'GET', 
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      });

      return response.json();
}

export default getRequest;