import React from "react";
import { Link } from "react-router-dom";
import "Assets/Styles/slideshow.scss";
import { SectionLoader } from "./index";
import AppUrl from "Api/config";

export default function SlideShow(props) {
  let {
    title1,
    title2,
    slideItems,
    itemUrl,
    loader,
    catUrl,
    sliderId,
    type,
    album,
    djmix,
  } = props;

  const moveRight = (e) => {
    let sliderContainer = document.querySelector(
      `${sliderId} .slideshow .slideshow-container`
    );

    let slider = document.querySelector(
      `${sliderId} .slideshow .slideshow-container .slideshow-slider`
    );

    let width = sliderContainer.offsetWidth;

    let scrollWidth = sliderContainer.scrollWidth;

    let left = isNaN(parseFloat(slider.style.left))
      ? 0
      : parseFloat(slider.style.left);

    if (scrollWidth > width) {
      slider.style.left = left - width + 5 + "px";
    }
  };

  const moveLeft = (e) => {
    let sliderContainer = document.querySelector(
      `${sliderId} .slideshow .slideshow-container`
    );

    let slider = document.querySelector(
      `${sliderId} .slideshow .slideshow-container .slideshow-slider`
    );

    let width = sliderContainer.offsetWidth;

    let curShift = slider.style.left;

    if (parseFloat(curShift) < 0) {
      slider.style.left = parseFloat(curShift) + parseFloat(width) - 5 + "px";
    }
  };

  return (
    <div className="slideshow">
      <div className="slideshow-info">
        <div className="slideshow-name">
          <span className="title1">{title1}</span>
          <span className="title2">{title2}</span>
          <span>
            <Link to={catUrl} className="view-all">
              View all
            </Link>
          </span>
        </div>
        <div className="slideshow-controls">
          <span className="previous" onClick={moveLeft}>
            <img
              alt="Previous"
              src={require("Assets/Images/player/slider-prev.svg").default}
            />
          </span>
          <span className="next" onClick={moveRight}>
            <img
              alt="next"
              src={require("Assets/Images/player/slider-next.svg").default}
            />
          </span>
        </div>
      </div>

      {loader ? (
        <SectionLoader />
      ) : (
        <div className="slideshow-container">
          <ul className="slideshow-slider">
            {type === "movie"
              ? slideItems.map((slide, index) => (
                  <li key={index} className="movie-item">
                    <Link
                      to={`${itemUrl}${slide.id}`}
                      className="item-img"
                      style={{
                        backgroundImage:
                          "url(" +
                          AppUrl +
                          "/storage/moviesThumbnail/" +
                          slide.thumbnail +
                          ")",
                      }}
                    ></Link>
                  </li>
                ))
              : type === "video"
              ? slideItems.map((slide, index) => (
                  <li key={index} className="video-item">
                    <Link
                      to={`${itemUrl}${slide.id}`}
                      className="item-img"
                      style={{
                        backgroundImage:
                          "url(" +
                          AppUrl +
                          "/storage/videosThumbnail/" +
                          slide.thumbnail +
                          ")",
                      }}
                    ></Link>
                    <div className="item-details">
                      <div
                        className="uploader-img"
                        style={{
                          backgroundImage:
                            "url(" +
                            AppUrl +
                            "/storage/avatars/" +
                            slide.user.image +
                            ")",
                        }}
                      ></div>

                      <div className="video-info">
                        <h5>{slide.title}</h5>
                        <p className="uploader">{slide.user.username}</p>
                        <p className="views">
                          {slide.views.length} view
                          {slide.views.length > 1 ? "s" : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              : slideItems.map((slide, index) => (
                  <li key={index} className="music-item">
                    <Link
                      to={`${itemUrl}${slide.id}`}
                      className="item-img"
                      style={{
                        backgroundImage:
                          slide.image_path !== "undefined"
                            ? album
                              ? "url(" +
                                AppUrl +
                                "/storage/album/" +
                                slide.image_path +
                                ")"
                              : djmix
                              ? "url(" +
                                AppUrl +
                                "/storage/djmixCover/" +
                                slide.image_path +
                                ")"
                              : "url(" +
                                AppUrl +
                                "/storage/musicCover/" +
                                slide.image_path +
                                ")"
                            : `url(${
                                require("Assets/Images/music/music-note.png").default
                              })`,
                      }}
                    ></Link>
                    <div className="item-details">
                      <h5>{slide.title}</h5>
                      <Link
                        to={
                          slide.artist_id ? `/artist/${slide.artist_id}` : "#"
                        }
                      >
                        {album ? "Album" : "Single"} -{"  "}
                        {djmix ? slide.dj : slide.artist_name}
                      </Link>
                    </div>
                  </li>
                ))}

            {slideItems.length === 0 ? (
              <p className="no-items">No content</p>
            ) : (
              ""
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
