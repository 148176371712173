import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Aside(props) {
  let { category, subcategories } = props;

  useEffect(() => {
    let path = window.location.pathname.replace("/", "");

    let el = document.querySelector(`a[href='/${path}'`);

    if (el !== null) el.classList.add("active");
  }, []);

  return (
    <aside>
      <h4>{category}</h4>

      <ul>
        {subcategories.map((subcat, index) => (
          <li key={index}>
            <Link to={subcat.link}>{subcat.name}</Link>
          </li>
        ))}
      </ul>

      <div className="actions">
        <p className="library">Library</p>
        <Link to={"#"} className="create-playlist">
          Create playlist
        </Link>
      </div>
    </aside>
  );
}
