export default class BaseRepository {
  static prepareParams = (param) => {
    let query = "";
    for (const [key, value] of Object.entries(param)) {
      query += `${key}= ${value}&`;
    }

    return query;
  };

  static serialize = (form) => {
    const fData = new FormData();

    for (let el in form) {
      if (
        form[el].name !== "namedItem" &&
        form[el].name !== "item" &&
        !form[el].disabled &&
        form[el].type !== "file" &&
        form[el].type !== "reset" &&
        typeof form[el].name !== "undefined" &&
        form[el].type !== "submit" &&
        form[el].type !== "button"
      ) {
        fData.append(form[el].name, form[el].value);
      } else if (form[el].type === "file") {
        fData.append(form[el].name, form[el].files[0]);
      }
    }

    return fData;
  };

  //Handles GET requests, bearer token is optional, depends on the requirement of the api endpoint
  static get = async (url, token = "", params = {}) => {
    // let body = prepareBody(data);
    let query = this.prepareParams(params);

    const response = await fetch(`${url}${query}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    return await response.json();
  };

  //Handles all post requests including file uploads, Bearer token is compulsory,
  //only authenticated users can post data to api
  static post = async (url, form, token = "", processed = false) => {
    let formData = processed ? form : this.serialize(form);

    const response = await fetch(`${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: formData,
    });

    return await response.json();
  };
}
