import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "Assets/Styles/navigator.scss";

export default function Navigator() {
  const navLinks = [
    {
      name: "Inside Africa",
      href: "/inside-africa?page=1",
    },
    {
      name: "Music",
      href: "/music",
    },
    {
      name: "Video",
      href: "/video",
    },
    {
      name: "Movies",
      href: "/movies",
    },
    {
      name: "Events",
      href: "/events",
    },
  ];

  useEffect(() => {
    let path = window.location.pathname.replace("/", "");

    let el = document.querySelector(`a[href='/${path}'`);

    if (el !== null) el.classList.add("active");

    let closeBtn = document.querySelector(".close-nav");

    let toggleBtn = document.querySelector(".navbar-toggler-icon");

    if (toggleBtn) {
      toggleBtn.addEventListener("click", function () {
        document.querySelector(".mobile-nav").style.opacity = 1;
        document.querySelector(".mobile-nav").style.visibility = "visible";
      });
    }

    if (closeBtn) {
      closeBtn.addEventListener("click", function () {
        document.querySelector(".mobile-nav").style.opacity = 0;
        document.querySelector(".mobile-nav").style.visibility = "hidden";
      });
    }
  }, []);

  const onNavLinkClick = (page) => {
    window.dataLayer.push({
      event: 'navigation_link_click',
      page
    })
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <Link className="navbar-brand" to="/">
          <img
            alt="Logo"
            src={require("Assets/Images/layout/logo1.svg").default}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            {navLinks.map((nav, index) => (
              <Link key={index} className="nav-item nav-link" to={nav.href} onClick={() => onNavLinkClick(nav.name)}>
                {nav.name}
              </Link>
            ))}
          </div>
        </div>
        <Link className="navbar-profile" to={"#"}>
          <img
            alt="Profile"
            src={require("Assets/Images/layout/profile2.svg").default}
            title="User profile"
          />
        </Link>
      </nav>
      {/* Mobile navigator */}
      <div className="mobile-nav">
        <ul>
          {navLinks.map((nav, index) => (
            <li key={index}>
              <Link to={nav.href}>{nav.name}</Link>
            </li>
          ))}
          <li>
            <Link to={"/artists"}>Artists</Link>
          </li>
        </ul>
        <div className="close-nav">
          <img
            alt="Close"
            src={require("Assets/Images/mobile/close.svg").default}
          ></img>
        </div>
      </div>
      {/* End of mobile navigator */}
    </>
  );
}
