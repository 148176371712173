import BaseRepository from "./BaseRepository";

const adminUrl = process.env.REACT_APP_ADMIN_API;

export default class NewsRepository extends BaseRepository {
  static loadNews = async ({ page = 1, limit = 10 }) => {
    let response = {};

    try {
      response = await this.get(`${adminUrl}/news?page=${page}&limit=${limit}`);
    } catch (e) {
      throw e;
    }

    return response;
  };

  static loadNewsById = async (id) => {
    let response = {};

    try {
      response = await this.get(`${adminUrl}/news/${id}`);
    } catch (e) {
      throw e;
    }

    return response;
  };
}
