import React from "react";
import "Assets/Styles/music-list.scss";
import { MusicPlayerContext } from "Context/AudioPlayerContext";
import AppUrl from "Api/config";

const MusicList = React.forwardRef((props, ref) => {
  let { list, djmix } = props;

  return (
    <MusicPlayerContext.Consumer>
      {(context) => (
        <table className="music-list">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Title</th>
              <th></th>
              <th></th>
              <th className="time-th">Time</th>
              <th></th>
            </tr>
          </thead>

          <tbody className="playlist" ref={context.playListRef}>
            {list.map((song, index) => (
              <tr
                onClick={(e) => {
                  context.clickSong(e);
                }}
                data-title={song.title}
                data-artist={song.artist_name}
                data-id={song.id}
                data-src={
                  djmix
                    ? AppUrl + "/storage/djmixAudio/" + song.music_path
                    : AppUrl + "/storage/musicAudio/" + song.music_path
                }
                data-cover={
                  song.image_path === "undefined"
                    ? require("Assets/Images/music/music-note-white.svg")
                        .default
                    : djmix
                    ? AppUrl + "/storage/djmixCover/" + song.image_path
                    : AppUrl + "/storage/musicCover/" + song.image_path
                }
                key={index}
                className={"song-row"}
              >
                <td className="icon text-center">
                  {index + 1}
                  <img
                    className="play"
                    alt="Play"
                    src={require("Assets/Images/player/play.svg").default}
                  />
                  <img
                    className="pause"
                    alt="pause"
                    src={require("Assets/Images/player/pause-list.svg").default}
                  />
                </td>
                <td className="title">
                  {song.title}
                  <small>
                    Artist -{" "}
                    {song.hasOwnProperty("dj") ? song.dj : song.artist_name}
                  </small>
                </td>
                <td className="share">
                  <img
                    alt="Share"
                    src={require("Assets/Images/player/share.svg").default}
                  />
                </td>
                <td className="download">
                  <a
                    href={
                      djmix
                        ? AppUrl + "/v1/admin/mix-download/" + song.id
                        : AppUrl + "/v1/admin/music-download/" + song.id
                    }
                  >
                    <img
                      alt="Download"
                      src={require("Assets/Images/player/download.svg").default}
                    />
                  </a>
                  <span>{song.total_download}</span>
                </td>
                <td className="time">{song.duration.replace(".", ":")}</td>
                <td className="total-plays">{song.views.length} play(s)</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </MusicPlayerContext.Consumer>
  );
});

export default MusicList;
